.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.tick {
  margin-right: 1rem;
}

.itemContent {
  text-align: left;
}

.alignLeft .item {
  justify-content: left;
}

.alignCenter .item {
  justify-content: center;
}

@media (min-width: 768px) {
  .alignCenter .item {
    justify-content: left;
  }
}
