.ctaButton,
.ctaButtonSecondary,
.ctaButtonDanger,
.ctaButtonInvert,
.ctaButtonDarkBG,
.ctaButtonLight {
  padding: 12px 20px;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

/* Primary */

.ctaButton {
  border-color: rgb(0, 169, 165);
  background-color: rgb(0, 169, 165);
}

.ctaButton:hover {
  border-color: rgba(0, 169, 165, 0.8);
  background-color: rgba(0, 169, 165, 0.8);
}

.ctaButton:active {
  border-color: rgba(0, 169, 165, 0.6) !important;
  background-color: rgba(0, 169, 165, 0.6) !important;
}

.ctaButton:focus {
  border-color: rgba(0, 169, 165, 0.6) !important;
  background-color: rgba(0, 169, 165, 0.6) !important;
  box-shadow: 0 0 0 .2rem rgba(0, 169, 165, 0.4) !important;
}

.ctaButton:disabled {
  border-color: rgba(0, 169, 165, 0.6) !important;
  background-color: rgba(0, 169, 165, 0.6) !important;
}

/* Light */

.ctaButtonLight {
  color: rgb(0, 169, 165);
  border-color: rgba(0, 169, 165, 0.1);
  background-color: rgba(0, 169, 165, 0.1);
}

.ctaButtonLight:hover {
  color: white;
  border-color: rgb(0, 169, 165);
  background-color: rgb(0, 169, 165);
}

.ctaButtonLight:active {
  color: white;
  border-color: rgb(0, 169, 165) !important;
  background-color: rgb(0, 169, 165) !important;
}

.ctaButtonLight:focus {
  color: rgb(0, 169, 165);
  border-color: rgba(0, 169, 165, 0.1) !important;
  background-color: rgba(0, 169, 165, 0.1) !important;
  box-shadow: 0 0 0 .2rem rgba(0, 169, 165, 0.4) !important;
}

.ctaButtonLight:disabled {
  color: rgb(0, 169, 165);
  border-color: rgba(0, 169, 165, 0.1) !important;
  background-color: rgba(0, 169, 165, 0.1) !important;
}

/* Dark BG */

.ctaButtonDarkBG {
  color: rgb(35, 37, 40);
  border-color: white;
  background-color: white;
}

.ctaButtonDarkBG:hover {
  color: white;
  border-color: rgb(0, 169, 165);
  background-color: rgb(0, 169, 165);
}

.ctaButtonDarkBG:active {
  color: white;
  border-color: rgb(0, 169, 165) !important;
  background-color: rgb(0, 169, 165) !important;
}

.ctaButtonDarkBG:focus {
  color: rgb(0, 169, 165);
  border-color: rgba(0, 169, 165, 0.6) !important;
  background-color: rgba(0, 169, 165, 0.6) !important;
  box-shadow: 0 0 0 .2rem rgba(0, 169, 165, 0.4) !important;
}

.ctaButtonDarkBG:disabled {
  color: rgba(0, 169, 165, 0.6);
  border-color: rgba(0, 169, 165, 0.6) !important;
  background-color: rgba(0, 169, 165, 0.6) !important;
}

/* Danger */

.ctaButtonDanger {
  border-color: rgb(255, 79, 79);
  background-color: rgb(255, 79, 79);
}

.ctaButtonDanger:hover {
  border-color: rgba(255, 79, 79, 0.8);
  background-color: rgba(255, 79, 79, 0.8);
}

.ctaButtonDanger:active {
  border-color: rgba(255, 79, 79, 0.6) !important;
  background-color: rgba(255, 79, 79, 0.6) !important;
}

.ctaButtonDanger:focus {
  border-color: rgba(255, 79, 79, 0.6) !important;
  background-color: rgba(255, 79, 79, 0.6) !important;
  box-shadow: 0 0 0 .2rem rgba(255, 79, 79, 0.4) !important;
}

.ctaButtonDanger:disabled {
  border-color: rgba(255, 79, 79, 0.6) !important;
  background-color: rgba(255, 79, 79, 0.6) !important;
}

/* Invert */

.ctaButtonInvert {
  color: rgb(0, 169, 165);
  border-color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
}

.ctaButtonInvert:hover {
  color: rgb(0, 169, 165);
  border-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.ctaButtonInvert:active {
  border-color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.ctaButtonInvert:focus {
  border-color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 0 0 .2rem rgba(255, 255, 255, 0.4) !important;
}

.ctaButtonInvert:disabled {
  border-color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}

/* Secondary */

.ctaButtonSecondary {
  border-color: rgb(200, 200, 200);
  background-color: rgb(200, 200, 200);
}

.ctaButtonSecondary:hover {
  border-color: rgba(200, 200, 200, 0.8);
  background-color: rgba(200, 200, 200, 0.8);
}

.ctaButtonSecondary:active {
  border-color: rgba(200, 200, 200, 0.6) !important;
  background-color: rgba(200, 200, 200, 0.6) !important;
}

.ctaButtonSecondary:focus {
  border-color: rgba(200, 200, 200, 0.6) !important;
  background-color: rgba(200, 200, 200, 0.6) !important;
  box-shadow: 0 0 0 .2rem rgba(200, 200, 200, 0.4) !important;
}

.ctaButtonSecondary:disabled {
  border-color: rgba(200, 200, 200, 0.6) !important;
  background-color: rgba(200, 200, 200, 0.6) !important;
}
