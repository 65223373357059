.title {
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 56px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  color: rgb(35, 37, 40);
}

.title .titleHighlight {
  color: rgb(0, 169, 165);
}

.subtitle {
  margin-bottom: 2rem;
  font-size: 16px;
  text-align: center;
}

.firstCta {
  margin-bottom: 3rem;
  text-align: center;
}

.firstCta button {
  padding: 1rem 3rem;
}

.noCreditCardNeeded {
  margin-top: 0.5rem;
  font-size: 12px;
}

.preview1,
.preview2,
.preview3 {
  text-align: center;
  margin-bottom: 6rem;
}

.preview1 {
  margin-bottom: 3rem;
}

.preview1 > div,
.preview2 > div,
.preview3 > div {
  display: inline-block;
  border: 1px solid #efefef;
  border-radius: 5px;
  overflow: hidden;
}

.subheading {
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}

.whyLearnSqlCta {
  text-align: center;
  margin-bottom: 2rem;
}

.valueProps {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.valueProps > div {
  margin-bottom: 3rem;
}

.valueProps > div > div {
  margin-bottom: 1rem;
  height: 70px;
  position: relative;
}

.valueProps > div > div > div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.valueProps h3 {
  font-size: 24px;
  font-weight: 400;
}

.valueProps h3,
.valueProps p {
  text-align: center;
}

.valueProps p {
  padding: 0 4rem;
  font-size: 16px;
}

.easiestWayToLearnHeading {
  margin-top: 5rem;
  margin-bottom: 3rem;
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
}

.easiestWayToLearnCta {
  text-align: center;
  margin-bottom: 2rem;
}

.easiestWayToLearnFeatures {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.easiestWayToLearnFeaturesLeft,
.easiestWayToLearnFeaturesRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.easiestWayToLearnFeaturesComputer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.easiestWayToLearnFeaturesComputer > div {
  text-align: center;
}

.easiestWayToLearnFeature:first-child {
  margin-bottom: 1rem;
}

.easiestWayToLearnFeatureIcon {
  text-align: center;
}

.easiestWayToLearnFeatureHeader {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.easiestWayToLearnFeatureDescription {
  font-size: 16px;
  line-height: 22px;
}

.relevantDatasets {
  margin-top: 6rem;
}

.relevantDatasets,
.learningFormat,
.chooseDatabase,
.everyRole {
  margin-bottom: 6rem;
}

.interactiveEditor {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.previewImage {
  display: inline-block;
  border: 1px solid #efefef;
  border-radius: 5px;
  overflow: hidden;
}

.previewImage img {
  max-width: 100%;
}

.relevantDatasetsHeading,
.learningFormatHeading,
.chooseDatabaseHeading,
.everyRoleHeading,
.interactiveEditorHeading {
  color: rgb(0, 169, 165);
  font-size: 14px;
  text-transform: uppercase;
  line-height: 26px;
}

.relevantDatasetsSubheading,
.learningFormatSubheading,
.chooseDatabaseSubheading,
.everyRoleSubheading,
.interactiveEditorSubheading {
  margin-bottom: 1rem;
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
}

.relevantDatasetsBody,
.learningFormatBody,
.chooseDatabaseBody,
.everyRoleBody,
.interactiveEditorBody {
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 34px;
}

.interactiveEditorBody {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.finalCtaContainer {
  background-color: rgb(35, 37, 40);
  color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.finalCtaHeading1 {
  margin-bottom: 3rem;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
}

.finalCtaButton {
  text-align: center;
}

.finalCtaButton button {
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 22px;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 22px;
  }

  .preview1 {
    margin-bottom: 6rem;
  }

  .preview1 > div,
  .preview2 > div,
  .preview3 > div,
  .previewImage {
    border-radius: 16px;
  }

  .easiestWayToLearnFeaturesComputer {
    margin-top: 0;
    margin-bottom: 0;
  }

  .finalCtaHeading1 {
    font-size: 56px;
    line-height: 60px;
  }
}
