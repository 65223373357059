.testimonialContainer {
  background-color: rgb(0, 169, 165);
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.testimonialContainer.first {
  padding-top: 5rem;
}

.testimonialContainer.last {
  padding-bottom: 5rem;
}

.testimonialContainer a {
  color: white;
}

.testimonialMessage {
  font-size: 14px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  line-height: 32px;
}

.testimonialMessage > div {
  border-left: 1px solid white;
  padding-left: 2rem;
}

.testimonialAvatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialAvatar > div {
  text-align: center;
}

.testimonialAvatar .testimonialAvatarBorder {
  display: inline-block;
  border-style: none;
  border-radius: 35px;
  overflow: hidden;
}

.testimonialAvatar .testimonialAvatarBorder,
.testimonialAvatar .testimonialAvatarBorder img {
  width: 70px;
  height: 70px;
}

.testimonialPersonDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonialName {
  font-size: 16px;
  font-weight: 700;
}

.testimonialTitle {
  font-size: 16px;
}
