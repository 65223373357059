.topBar {
  padding-top: 2rem;
}

.topBarLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topBar .topCta {
  text-align: right;
}

.topBar .mobileCta {
  margin-top: 1rem;
  text-align: center;
}

.topCta .forConsumerLink,
.topCta .forBusinessLink,
.topCta .signInLink {
  display: inline;
}

.mobileCta .forConsumerLink,
.mobileCta .forBusinessLink {
  margin-bottom: 1rem;
}

.forConsumerLink a,
.forBusinessLink a,
.signInLink a {
  color: rgb(101, 100, 100);
}

.forConsumerLink a:hover,
.forBusinessLink a:hover,
.signInLink a:hover {
  color: rgb(0, 169, 165);
}

.topCta .forConsumerLink a,
.topCta .forBusinessLink a,
.topCta .signInLink a {
  margin-right: 2rem;
}

.topCta button {
  font-size: 16px;
}
