.container,
.inner,
.video {
  display: block;
  margin: 0;
  padding: 0;
}

.video,
.image {
  max-width: 100%;
}
